import { UserContext } from '@/lib/UserContext'
import { HamburgerIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import { NavDashboard } from './NavDashboard'

export const Nav = () => {
  const userData = useContext(UserContext)
  let isLoggedIn = !!userData({ guarded: false })
  const router = useRouter()
  const [isDashboard, setIsDashboard] = useState(
    router.pathname.split('/')[1] == 'dashboard' ? true : false
  )

  useEffect(() => {
    setIsDashboard(router.pathname.split('/')[1] == 'dashboard' ? true : false)
  }, [router.pathname])

  return (
    <Flex
      as='nav'
      bgColor={isDashboard ? 'gray.100' : 'blue.900'}
      color={isDashboard ? 'blue.900' : 'gray.100'}
      minH='20'
      p='4'
      position='fixed'
      w='100%'
      zIndex={100}>
      <Flex alignItems='center' color='gray.900'>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label='Options'
            icon={<HamburgerIcon />}
            variant='outline'
            width={2}
            bgColor={isDashboard ? 'gray.100' : 'blue.900'}
            borderColor={isDashboard ? 'blue.900' : 'gray.100'}
            color={isDashboard ? 'blue.900' : 'gray.100'}
            _hover={isDashboard ? { color: 'blue.900' } : { color: 'gray.100' }}
            _focus={
              isDashboard
                ? { outlineColor: 'gray.900', color: 'gray.900' }
                : { outlineColor: 'gray.100', color: 'gray.100' }
            }
            _expanded={
              isDashboard
                ? { bg: 'gray.900', color: 'gray.100' }
                : { bg: 'gray.100', color: 'gray.900' }
            }
          />
          <MenuList
            bgColor='gray.900'
            border='none'
            borderRadius='0'
            color='gray.100'
            display='grid'
            gridTemplateColumns={{ base: '1fr', lg: '1fr 1fr 1fr' }}
            p={{ base: 12, lg: 8 }}
            gridGap={12}>
            <MenuGroup>
              <MenuItem>
                <NextLink href='/nation/projects' passHref legacyBehavior>
                  <Link
                    textTransform='uppercase'
                    fontSize='lg'
                    fontWeight='bold'>
                    Deboss Nation
                  </Link>
                </NextLink>
              </MenuItem>
              <MenuItem>
                <NextLink href='/nation/projects' passHref legacyBehavior>
                  <Link>Member Projects</Link>
                </NextLink>
              </MenuItem>
              <MenuItem>
                <NextLink href='/nation/categories' passHref legacyBehavior>
                  <Link>Categories</Link>
                </NextLink>
              </MenuItem>
              <MenuItem>
                <NextLink href='/nation/map/projects' passHref legacyBehavior>
                  <Link>Around the World</Link>
                </NextLink>
              </MenuItem>
              <MenuItem>
                <NextLink href='/spotlight' passHref legacyBehavior>
                  <Link>Spotlight Articles</Link>
                </NextLink>
              </MenuItem>
              <MenuItem>
                <NextLink href='/downloads' passHref legacyBehavior>
                  <Link>Downloads</Link>
                </NextLink>
              </MenuItem>
              {isLoggedIn && (
                <MenuItem>
                  <NextLink
                    href='/dashboard/projects/create'
                    passHref
                    legacyBehavior>
                    <Link>Show Us Your Project</Link>
                  </NextLink>
                </MenuItem>
              )}
              {!isLoggedIn && (
                <MenuItem>
                  <NextLink href='/login' passHref legacyBehavior>
                    <Link>Login</Link>
                  </NextLink>
                </MenuItem>
              )}
            </MenuGroup>
            <MenuGroup>
              <Text
                textTransform='uppercase'
                fontSize='lg'
                fontWeight='bold'
                ml={3}
                mb={2}
                mt={2}>
                Deboss Garage
              </Text>
              <MenuItem>
                <NextLink href='/builds' passHref legacyBehavior>
                  <Link>Builds</Link>
                </NextLink>
              </MenuItem>
              <MenuItem>
                <Link href='https://merch.debossgarage.com'>Store</Link>
              </MenuItem>
              <MenuItem>
                <NextLink href='/events' passHref legacyBehavior>
                  <Link>Events</Link>
                </NextLink>
              </MenuItem>
              <MenuItem>
                <NextLink href='/contact' passHref legacyBehavior>
                  <Link>Contact</Link>
                </NextLink>
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
        <NextLink href='/' passHref legacyBehavior>
          <Link>
            <Image
              marginLeft={4}
              src={
                isDashboard
                  ? '/img/deboss-logo-dark.svg'
                  : '/img/deboss-logo.svg'
              }
              alt='Deboss Garage'
            />
          </Link>
        </NextLink>
      </Flex>
      <Flex marginLeft='auto' alignItems='center'>
        <Box display={{ base: 'none', md: 'block' }}>
          {isLoggedIn ? null : (
            <NextLink href='/login' passHref legacyBehavior>
              <Link>Login</Link>
            </NextLink>
          )}
        </Box>
        {isLoggedIn ? (
          <Flex align='center' gap={4}>
            <NextLink href='/dashboard' passHref legacyBehavior>
              <Link
                mr={2}
                textTransform='uppercase'
                display={{ base: 'none', sm: 'block' }}>
                Dashboard
              </Link>
            </NextLink>
            <NavDashboard />
          </Flex>
        ) : (
          <NextLink href='/membership' passHref legacyBehavior>
            <Button as='a' colorScheme='red' ml='8'>
              Join Deboss Nation
            </Button>
          </NextLink>
        )}
      </Flex>
    </Flex>
  )
}
